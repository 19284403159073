import Auth from '@aws-amplify/auth';

export async function Init(): Promise<void> {
  const config = {
    stage: "gamma",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:778228ec-544b-4366-92a7-7d3b556b672d",
    aws_user_pools_id: "us-east-1_2cWyXhQ8j",
    aws_user_pools_web_client_id: "52ereuthsf96qmd37mo93kc4r0",
    Auth: {
      identityPoolId: "us-east-1:778228ec-544b-4366-92a7-7d3b556b672d",
      region: "us-east-1",
      userPoolId: "us-east-1_2cWyXhQ8j",
      userPoolWebClientId: "52ereuthsf96qmd37mo93kc4r0",
    },
    oauth: {
      domain: `rtw-tool-gamma-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://gamma.na.rtw.whs.amazon.dev",
      redirectSignOut: "https://gamma.na.rtw.whs.amazon.dev",
      scope: ['openid', 'profile'],
      responseType: 'code',
    },
  };
  Auth.configure(config);
}