import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { setIsAUSTINDown } from "src/store/newJmrPageSlice";
import { addError, refreshPage } from "src/store/errorBoundarySlice";
import store from "../store/store";
import authService from "src/services/authService";

const apiClient = axios.create({
  baseURL: "https://gamma.na.rtw-api.whs.amazon.dev",
});

axiosRetry(apiClient, {
  retries: 1, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    // use store.dispatch(getUser()) will make retry instantly, which cause more chance to having race condition
    authService.login();
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error: AxiosError) => {
    // if retry condition is not specified, by default idempotent requests are retried
    console.log(JSON.stringify(error));
    const status = error.response?.status;
    if (!status || status != 401) {
      return false;
    }
    return true;
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 403) {
      store.dispatch(refreshPage());
    } else if (error.response?.status !== 429) {
      // if the response is from the incident API
      // swallow the error let the React component handle the error messaging
      if (isErrorFromIncidentAPI(error)) {
        store.dispatch(setIsAUSTINDown(true));
        return;
      }
      // if the response is from query AUSTIN case id,
      // swallow the error let React component handle the redirect
      if (isErrorFromGetAccommodationIdByCaseIdAndLoginApi(error)) {
        return error.response?.data;
      } else if (error.response?.data?.message) {
        store.dispatch(addError(error.response?.data?.message));
      } else {
        store.dispatch(addError(error?.message));
      }
    }
  }
);

const isErrorFromGetAccommodationIdByCaseIdAndLoginApi = (error: AxiosError) => {
  return error.response?.status === 400 && error.response?.data?.path.startsWith("/accommodation/type");
};

const isErrorFromIncidentAPI = (error: AxiosError) => {
  return error.response?.status === 500 && error.response?.data?.path.startsWith("/incident/associate/");
};

export default apiClient;
